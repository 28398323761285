import { BooleanStatus } from 'modules/administration/models/boolean-status';
import { Manufacturer }  from 'modules/map/models/manufacturer';

export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Returns a new instance of the model
     *
     * @returns {Manufacturer}
     */
    model() {
        let model = new Manufacturer();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            label: 'form.field.name',
            size:  12,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            label: 'form.field.status',
            size:  12,
        };

        return [
            [this.name],
            [this.status_id],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    globalSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.close',
            action:     () => viewModel.dialogController.close(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => viewModel.resetForm(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
