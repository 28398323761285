import { DialogService }             from 'aurelia-dialog';
import { inject }                    from 'aurelia-framework';
import { CreateModal }               from 'modules/map/management/manufacturers/create-modal';
import { ManufacturersRepository }   from 'modules/map/management/manufacturers/services/repository';
import { MatAppProposalsRepository } from 'modules/map/mat-app-proposals/services/repository';
import { ReclassifyManufacturer }    from 'modules/map/models/reclassify-manufacturer';

@inject(ManufacturersRepository, MatAppProposalsRepository, DialogService)
export class FormSchema {

    /**
     * Constructor
     *
     * @param manufacturersRepository
     * @param mapRepository
     * @param dialogService
     */
    constructor(manufacturersRepository, mapRepository, dialogService) {
        this.manufacturersRepository = manufacturersRepository;
        this.mapRepository           = mapRepository;
        this.dialogService           = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ReclassifyManufacturer}
     */
    model() {
        return new ReclassifyManufacturer();
    }

    /**
     * Returns schema
     *
     * @param viewModel
     */
    schema(viewModel) {

        this.other_manufacturer = {
            type:         'multiselect-native',
            key:          'other_manufacturer',
            label:        'form.field.other-manufacturer',
            remoteSource: () => this.mapRepository.otherManufacturers(),
            size:         12,
            observers:    [
                (newValue) => {
                    const hasItems = this.other_manufacturer.instance.selectedItems().length > 0;
                    this.mat_app_proposals.instance.disable(!hasItems);
                    this.mat_app_proposals.instance.required(hasItems);

                    if (hasItems) {
                        this.mat_app_proposals.instance.fetchData();
                    }
                },
            ],
            hidden:       viewModel.selected_ids !== null,
        };

        this.mat_app_proposals = {
            type:         'multiselect-native',
            key:          'mat_app_proposals',
            label:        'form.field.mat-app-proposals',
            remoteSource: () => viewModel.model.other_manufacturer
                ? this.mapRepository.allByOtherManufacturer({ other_manufacturers: viewModel.model.other_manufacturer })
                : Promise.resolve([]),
            required:     false,
            attributes:   {
                disabled: true,
            },
            size:         12,
            hidden:       viewModel.selected_ids !== null,
        };

        this.manufacturer_id = {
            type:         'select2',
            key:          'manufacturer_id',
            label:        'form.field.manufacturer',
            remoteSource: () => this.manufacturersRepository.active(),
            size:         12,
            inputGroup:   {
                position: 'right',
                visible:  true,
                button:   {
                    visible: true,
                    action:  () => this.openCreateModal(viewModel),
                    icon:    {
                        class: 'icon-plus2',
                    },
                },
            },
        };

        return [
            [this.other_manufacturer],
            [this.mat_app_proposals],
            [this.manufacturer_id],
        ];
    }

    /**
     Opens the creation manufacturer modal
     */
    openCreateModal(viewModel) {
        this.dialogService.open({
            viewModel: CreateModal,
            model:     {},
        }).whenClosed(async (response) => {
            if (!response.wasCancelled) {
                await this.manufacturer_id.instance.fetchData();
                viewModel.model.manufacturer_id = response.output.model.id;
            }
        });
    }
}
